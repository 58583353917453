<router-outlet />

@if (appLoading()) {
  <ox-loader [show]="true" />
}

@if (showVersionUpdate()) {
  <div
    @slideFromBottom
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 z-50 flex px-4 py-6 items-end sm:p-6"
  >
    <div
      class="flex w-full relative flex-col items-end space-y-4 group hover:-translate-y-2 transition-transform"
    >
      <div
        class="pointer-events-auto relative w-full max-w-xs overflow-hidden rounded-lg bg-white shadow-lg group-hover:shadow-primary-300 transition-shadow ring-1 ring-black ring-opacity-5"
      >
        <span
          class="absolute top-0 left-0 z-0 h-4 w-4 rounded-full bg-white group-hover:bg-primary-50/50 transition-all duration-300 group-hover:scale-[50]"
        ></span>
        <div class="p-4 relative">
          <div class="flex items-start">
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p
                class="text-sm font-medium text-primary-900"
                i18n="@@aversionUpdateAlertVersionAvailable"
              >
                Version available
              </p>
              <p
                class="mt-2.5 text-black/70 text-xs"
                i18n="@@aversionUpdateAlertMessageText"
              >
                New version of orq.ai is available to update. Refresh to update.
              </p>
              <div class="mt-2.5 flex space-x-7">
                <button
                  (click)="reloadPage()"
                  type="button"
                  class="rounded-md text-sm font-medium text-primary-600 transition-colors hover:text-primary-500 focus:outline-none"
                  i18n="@@aversionUpdateAlertNow"
                >
                  Update now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
