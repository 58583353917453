export const buildApiDefinition = ({
  baseUrl,
  clusterUrl,
}: { baseUrl: string; clusterUrl: string }) => ({
  contacts: `${clusterUrl}/v2/contacts`,
  fields: `${baseUrl}/fields`,
  rules: `${baseUrl}/rules`,
  prompts: `${baseUrl}/prompts`,
  workspaces: `${baseUrl}/workspaces`,
  groups: `${baseUrl}/groups`,
  permissions: `${baseUrl}/permissions`,
  domains: `${baseUrl}/domains`,
  users: `${baseUrl}/users`,
  accounts: `${baseUrl}/accounts`,
  profile: `${baseUrl}/me`,
  billing: `${baseUrl}/billing`,
  storage: `${baseUrl}/storage`,
  onboarding: `${baseUrl}/onboarding`,
  metrics: `${baseUrl}/metrics`,
  playground: `${baseUrl}/playgrounds`,
  deployments: `${baseUrl}/deployments`,
  integrations: `${baseUrl}/integrations`,
  experiments: `${baseUrl}/experiments`,
  utils: `${baseUrl}/utils`,
  humanEvals: `${baseUrl}/human-evals`,
  workflowsRuns: `${baseUrl}/workflow-runs`,
  evals: `${baseUrl}/evals`,
  apiKeys: `${baseUrl}/api-keys`,
  webhooks: `${clusterUrl}/v2/webhooks`,
  arbac: {
    roles: `${baseUrl}/arbac/roles`,
    resources: `${baseUrl}/arbac/resources`,
    policies: `${baseUrl}/arbac/policies`,
  },
  ai: {
    models: `${baseUrl}/ai/models`,
  },
  models: `${clusterUrl}/v2/models`,
  knowledge: `${clusterUrl}/v2/knowledge`,
  resources: {
    testCases: `${baseUrl}/resources/test-cases`,
    variableCollection: `${baseUrl}/resources/variables-collection`,
    tools: `${baseUrl}/resources/tools`,
  },
});
