import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import { environment } from '@orquesta/environments';
import { AppComponent } from './app/app.component';
import { configureAppConfig } from './app/app.config';
import { buildApiDefinition } from './environments/api-definition';
import { RuntimeEnvironment } from './environments/environment.type';

fetch('/environment.json')
  .then((response) => response.json())
  .then((config) => {
    const runtimeEnvironment = config as RuntimeEnvironment;

    registerLocaleData(en);

    if (environment.production) {
      enableProdMode();
    }

    runtimeEnvironment.api = buildApiDefinition({
      baseUrl: runtimeEnvironment.v1Url,
      clusterUrl: runtimeEnvironment.v2Url,
    });

    const appConfig = configureAppConfig(runtimeEnvironment);

    bootstrapApplication(AppComponent, appConfig);
  });
