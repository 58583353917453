import { inject } from '@angular/core';
import {
  AngularFireAuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';
import { CanMatchFn, Routes } from '@angular/router';
import { provideState } from '@ngrx/store';
import { OrquestaRoute, OrquestaRule } from '@orquesta/models/common';
import { appFeature } from '@orquesta/state/app';
import { currentUserFeature } from '@orquesta/state/current-user';
import { OrquestaFacade } from '@orquesta/state/orquesta';
import { workspacesProviders } from '@orquesta/state/workspaces';
import { map } from 'rxjs';
import {
  nonWaitlistGuard,
  onboardingGuard,
  setPasswordGuard,
  waitlistGuard,
} from './guards';

const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(['auth/login']);
const redirectToWorkspace = () => redirectLoggedInTo(['']);

const showOnboarding = (value: boolean): CanMatchFn => {
  return () => {
    const orquesta = inject(OrquestaFacade);

    return orquesta
      .queryRule(OrquestaRule.EnableOnboarding, false)
      .pipe(map((enableOnboarding) => enableOnboarding === value));
  };
};

export const routes: Routes = [
  {
    path: 'auth',
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectToWorkspace,
    },
    children: [
      {
        path: 'login',
        loadComponent: () =>
          import('@orquesta/auth/login').then(
            ({ LoginComponent }) => LoginComponent,
          ),
      },
      {
        path: 'resetPassword',
        loadComponent: () =>
          import('@orquesta/auth/reset-password').then(
            ({ ResetPasswordComponent }) => ResetPasswordComponent,
          ),
      },
      {
        path: 'setPassword',
        canActivate: [setPasswordGuard()],
        loadComponent: () =>
          import('@orquesta/auth/set-password').then(
            ({ SetPasswordComponent }) => SetPasswordComponent,
          ),
      },
      /*  {
        path: 'signup',
        loadComponent: () => import('@orquesta/auth/signup').then(({ SignUpComponent }) => SignUpComponent),
      }, */
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
      },
    ],
  },
  {
    path: 'waitlist',
    loadComponent: () =>
      import('@orquesta/auth/waitlist').then(
        ({ WaitlistComponent }) => WaitlistComponent,
      ),
    canActivate: [AngularFireAuthGuard, nonWaitlistGuard()],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'onboarding',
    title: 'Onboarding',
    loadComponent: () =>
      import('@orquesta/features/onboarding').then(
        (m) => m.OnboardingComponent,
      ),
    canActivate: [AngularFireAuthGuard, waitlistGuard()],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
    providers: workspacesProviders,
    canMatch: [showOnboarding(false)],
  },
  {
    path: OrquestaRoute.Onboarding,
    title: 'Onboarding',
    providers: workspacesProviders,
    canActivate: [AngularFireAuthGuard, waitlistGuard(), onboardingGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
    loadComponent: () =>
      import('@orquesta/features/onboard').then((m) => m.OnboardingComponent),
  },
  {
    path: OrquestaRoute.CreateWorkspace,
    title: 'Create Workspace',
    loadComponent: () =>
      import('@orquesta/features/onboard').then((m) => m.OnboardingComponent),
    canActivate: [AngularFireAuthGuard, waitlistGuard()],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
    providers: workspacesProviders,
  },
  {
    path: 'invite',
    title: 'Invite',
    loadChildren: () =>
      import('@orquesta/features/invite').then((m) => m.InviteModule),
  },
  {
    path: 'not-found',
    data: {
      title: $localize`Something went wrong`,
      description: $localize`The workspace you are looking for does not exist or you do not have access to it.`,
    },
    loadComponent: () =>
      import('@orquesta/features/404').then((m) => m.NotFoundComponent),
    canActivate: [waitlistGuard()],
  },
  {
    path: 'status-page/:status',
    loadComponent: () =>
      import('@orquesta/features/not-authorized').then(
        ({ StatusPageComponent }) => StatusPageComponent,
      ),
    canActivate: [waitlistGuard()],
  },
  {
    path: '',
    loadChildren: () =>
      // eslint-disable-next-line @nx/enforce-module-boundaries
      import('@orquesta/features/workspaces/routing').then(
        (m) => m.WorkspaceRoutingModule,
      ),
    canActivate: [waitlistGuard()],
    providers: [provideState(appFeature), provideState(currentUserFeature)],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
