import { IMAGE_LOADER, type ImageLoaderConfig } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import {
  TitleStrategy,
  provideRouter,
  withComponentInputBinding,
  withRouterConfig,
} from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideTippyConfig } from '@ngneat/helipopper';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { Action, ActionReducer, provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideHotToastConfig } from '@ngxpert/hot-toast';
import { provideOrquesta } from '@orquesta/angular';
import { TIPPY_CONFIGURATION } from '@orquesta/constants/common';
import {
  ACCOUNTS_API,
  BILLING_API,
  CONTACTS_API,
  CURRENT_USER_API,
  DEPLOYMENTS_API,
  DOMAIN_API,
  ENVIRONMENT_HOST,
  ENVIRONMENT_NAME,
  EVALS_API,
  EXPERIMENTS_API,
  FIELDS_API,
  GENERATE_API,
  GROUPS_API,
  HUMAN_EVALS_API,
  KNOWLEDGE_API,
  METRICS_API,
  ONBOARDING_API,
  PLAYGROUND_API,
  PLAYGROUND_API_V2,
  PROMPTS_API_URL,
  RESOURCE_TOOLS_API,
  RULES_API,
  SIMULATION_API,
  STORAGE_API,
  STRIPE_PUBLISHABLE_KEY,
  TEST_CASES_API,
  TOKENIZE_API_URL,
  TOKENS_API,
  UNSTRUCTURED_API,
  USERS_API,
  UTILS_API,
  VARIABLE_COLLECTION_API,
  WORKFLOWS_RUNS_API,
  WORKSPACE_API,
} from '@orquesta/constants/tokens';
import { MODELS_API, MODELS_API_V2 } from '@orquesta/data/ai/models';

import { WEBHOOKS_API } from '@orq/data/webhooks';
import { AccountIdInterceptor } from '@orq/platform/interceptors/account-id-interceptor';
import {
  DATA_PROPAGATION_API,
  DATA_PROPAGATION_TOKEN,
} from '@orquesta/data/data-propagation';
import { INTEGRATIONS_API } from '@orquesta/data/integrations';
import { PERMISSIONS_API } from '@orquesta/data/permissions';
import { environment } from '@orquesta/environments';
import { ConfirmationModule } from '@orquesta/features/common/confirmation';
import { SafeAny } from '@orquesta/models/common';
import { FEEDBACK_API } from '@orquesta/models/feedback/tokens';
import { TokenInterceptor } from '@orquesta/platform/interceptors/token-interceptor';
import { WorkspaceInterceptor } from '@orquesta/platform/interceptors/workspace-interceptor';
import { modelProviders } from '@orquesta/state/ai/models';
import { AppEffects, appFeature } from '@orquesta/state/app';
import { ClipboardEffects } from '@orquesta/state/clipboard';
import {
  CodeSnippetEffects,
  codeSnippetFeature,
} from '@orquesta/state/code-snippet';
import {
  CurrentUserActions,
  currentUserProviders,
} from '@orquesta/state/current-user';
import { domainsProviders } from '@orquesta/state/domains';
import { humanEvalProviders } from '@orquesta/state/human-evals';
import { promptsMetricsFeature } from '@orquesta/state/metrics';
import { orquestaProviders } from '@orquesta/state/orquesta';
import { RouterEffects } from '@orquesta/state/router';
import { ThemeEffects, themeFeature } from '@orquesta/state/theme';
import { ToastEffects } from '@orquesta/state/toast';
import { usersProviders } from '@orquesta/state/users';
import { workspacesProviders } from '@orquesta/state/workspaces';
import * as Sentry from '@sentry/angular-ivy';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { provideEchartsCore } from 'ngx-echarts';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import { provideMarkdown } from 'ngx-markdown';
import { RuntimeEnvironment } from '../environments/environment.type';
import { routes } from './routes';
import { TitlePageStrategyService } from './services';

export function configureAppConfig(
  runtimeEnvironment: RuntimeEnvironment,
): ApplicationConfig {
  Sentry.init({
    dsn: 'https://bff8a6c8a1e9420bb24d06c043358fae@o1256669.ingest.sentry.io/4504974836826112',
    environment: runtimeEnvironment.name,
    denyUrls: [/localhost/],
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
      }),
      Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: environment.production ? 0.5 : 1.0,
    tracePropagationTargets: ['orquesta.cloud', 'orquesta.dev', 'orq.ai'],
    profilesSampleRate: environment.production ? 0.5 : 1.0,
    replaysOnErrorSampleRate: 0.5,
  });

  const resetReducer =
    (reducer: ActionReducer<SafeAny>) => (state: SafeAny, action: Action) => {
      if (action.type === CurrentUserActions.signOut.type) {
        return reducer(
          {
            app: {
              loading: false,
            },
          },
          action,
        );
      }
      return reducer(state, action);
    };

  return {
    providers: [
      provideAnimations(),
      provideAnimationsAsync(),
      provideHttpClient(
        withInterceptors([
          TokenInterceptor,
          WorkspaceInterceptor,
          AccountIdInterceptor,
        ]),
      ),
      provideTippyConfig(TIPPY_CONFIGURATION),
      provideRouter(
        routes,
        withRouterConfig({
          onSameUrlNavigation: 'reload',
        }),
        withComponentInputBinding(),
      ),
      provideStore(
        {},
        {
          metaReducers: [resetReducer],
          runtimeChecks: {
            strictActionImmutability: false,
          },
        },
      ),
      provideStoreDevtools({
        name: 'orq.ai',
        trace: false,
        traceLimit: 25,
        logOnly: environment.production,
      }),
      provideRouterStore({}),
      provideState(appFeature),
      provideState('router', routerReducer),
      provideState(themeFeature),
      provideState(codeSnippetFeature),
      provideState(promptsMetricsFeature),
      provideEffects(ToastEffects),
      provideEffects(ClipboardEffects),
      provideEffects(RouterEffects),
      provideEffects(ThemeEffects),
      provideEffects(CodeSnippetEffects),
      provideEffects(AppEffects),
      ...domainsProviders,
      ...orquestaProviders,
      ...modelProviders,
      ...usersProviders,
      ...currentUserProviders,
      ...workspacesProviders,
      ...humanEvalProviders,
      provideOrquesta({
        api_key: runtimeEnvironment.orquestaApiKey,
        ttl: 3600,
      }),
      provideHotToastConfig({
        stacking: 'depth',
        visibleToasts: 5,
        position: 'bottom-right',
        autoClose: true,
        className: 'orquesta-toast-container',
      }),
      provideMarkdown(),
      provideEchartsCore({
        echarts: () => import('echarts'),
      }),
      provideAuth(() => getAuth()),
      provideFirebaseApp(() =>
        initializeApp(runtimeEnvironment.firebaseConfig),
      ),
      provideAnalytics(() => getAnalytics()),
      provideFirestore(() => getFirestore()),
      importProvidersFrom([
        AngularFireModule.initializeApp(runtimeEnvironment.firebaseConfig),
        ConfirmationModule,
        RecaptchaV3Module,
        HighlightModule,
        NzMessageModule,
        NzDrawerModule,
        NzModalModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          registrationStrategy: 'registerWhenStable:5000',
        }),
      ]),
      { provide: TitleStrategy, useClass: TitlePageStrategyService },
      {
        provide: HIGHLIGHT_OPTIONS,
        useValue: {
          coreLibraryLoader: () => import('highlight.js/lib/core'),
          languages: {
            typescript: () => import('highlight.js/lib/languages/typescript'),
            curl: () => import('highlight.js/lib/languages/bash'),
            python: () => import('highlight.js/lib/languages/python'),
            json: () => import('highlight.js/lib/languages/json'),
            go: () => import('highlight.js/lib/languages/go'),
          },
          themePath: 'assets/styles/stackoverflow-light.css',
        },
      },
      {
        provide: ENVIRONMENT_NAME,
        useValue: runtimeEnvironment.name,
      },
      { provide: ENVIRONMENT_HOST, useValue: runtimeEnvironment.host },
      { provide: DOMAIN_API, useValue: runtimeEnvironment.api.domains },
      {
        provide: SIMULATION_API,
        useValue: runtimeEnvironment.simulationApi,
      },
      {
        provide: RECAPTCHA_V3_SITE_KEY,
        useValue: runtimeEnvironment.recaptchaSiteKey,
      },
      { provide: RULES_API, useValue: runtimeEnvironment.api.rules },
      { provide: CONTACTS_API, useValue: runtimeEnvironment.api.contacts },
      { provide: FIELDS_API, useValue: runtimeEnvironment.api.fields },
      {
        provide: USERS_API,
        useValue: runtimeEnvironment.api.users,
      },
      { provide: CURRENT_USER_API, useValue: runtimeEnvironment.api.profile },
      { provide: BILLING_API, useValue: runtimeEnvironment.api.billing },
      {
        provide: STRIPE_PUBLISHABLE_KEY,
        useValue: runtimeEnvironment.stripePublishableKey,
      },
      { provide: MODELS_API, useValue: runtimeEnvironment.api.ai.models },
      { provide: MODELS_API_V2, useValue: runtimeEnvironment.api.models },
      { provide: ONBOARDING_API, useValue: runtimeEnvironment.api.onboarding },
      { provide: ACCOUNTS_API, useValue: runtimeEnvironment.api.accounts },
      { provide: USERS_API, useValue: runtimeEnvironment.api.users },
      { provide: TOKENS_API, useValue: runtimeEnvironment.api.apiKeys },
      { provide: WORKSPACE_API, useValue: runtimeEnvironment.api.workspaces },
      { provide: GROUPS_API, useValue: runtimeEnvironment.api.groups },
      {
        provide: PERMISSIONS_API,
        useValue: runtimeEnvironment.api.permissions,
      },
      { provide: PROMPTS_API_URL, useValue: runtimeEnvironment.api.prompts },
      { provide: TOKENIZE_API_URL, useValue: runtimeEnvironment.tokenizerApi },
      { provide: METRICS_API, useValue: runtimeEnvironment.api.metrics },
      { provide: PLAYGROUND_API, useValue: runtimeEnvironment.api.playground },
      {
        provide: PLAYGROUND_API_V2,
        useValue: runtimeEnvironment.playgroundsApi,
      },
      { provide: GENERATE_API, useValue: runtimeEnvironment.generateApi },
      {
        provide: DATA_PROPAGATION_API,
        useValue: runtimeEnvironment.propagationApi,
      },
      {
        provide: DATA_PROPAGATION_TOKEN,
        useValue: runtimeEnvironment.tinybirdToken,
      },
      {
        provide: INTEGRATIONS_API,
        useValue: runtimeEnvironment.api.integrations,
      },
      {
        provide: EXPERIMENTS_API,
        useValue: runtimeEnvironment.api.experiments,
      },
      { provide: UTILS_API, useValue: runtimeEnvironment.api.utils },
      { provide: HUMAN_EVALS_API, useValue: runtimeEnvironment.api.humanEvals },
      { provide: EVALS_API, useValue: runtimeEnvironment.api.evals },
      {
        provide: TEST_CASES_API,
        useValue: runtimeEnvironment.api.resources.testCases,
      },
      {
        provide: DEPLOYMENTS_API,
        useValue: runtimeEnvironment.api.deployments,
      },
      { provide: KNOWLEDGE_API, useValue: runtimeEnvironment.knowledgeApi },
      { provide: STORAGE_API, useValue: runtimeEnvironment.api.storage },
      {
        provide: RESOURCE_TOOLS_API,
        useValue: runtimeEnvironment.api.resources.tools,
      },
      {
        provide: VARIABLE_COLLECTION_API,
        useValue: runtimeEnvironment.api.resources.variableCollection,
      },
      {
        provide: WORKFLOWS_RUNS_API,
        useValue: runtimeEnvironment.api.workflowsRuns,
      },
      {
        provide: WEBHOOKS_API,
        useValue: runtimeEnvironment.api.webhooks,
      },
      {
        provide: FEEDBACK_API,
        useValue: runtimeEnvironment.feedbackApi,
      },
      {
        provide: IMAGE_LOADER,
        useValue: (config: ImageLoaderConfig) => {
          if (config.src.includes('assets')) {
            return config.src;
          }

          const ref = config.src.split('%').slice(0, -1).join('%');
          const name = config.src.split('%')?.slice(-1)?.[0]?.split('.')?.[0];

          if (!config.width || !name) return config.src;

          return `${ref}%2Foptimized%${name}_${config.width}x${config.width}.webp?alt=media`;
        },
      },
      { provide: NZ_I18N, useValue: en_US },
      { provide: 'googleTagManagerId', useValue: 'GTM-KQ5C74S' },
      {
        provide: UNSTRUCTURED_API,
        useValue: runtimeEnvironment.unstructuredApi,
      },
    ],
  };
}
