import { inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { OrquestaRoute } from '@orquesta/models/common';
import { currentUserFeature } from '@orquesta/state/current-user';
import { isNonNullish, isNullish } from '@orquesta/utils/common';
import { filter, of, switchMap, take } from 'rxjs';

export function waitlistGuard(): CanActivateFn {
  return (_: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router = inject(Router);
    const store = inject(Store);
    const auth = inject(AngularFireAuth);

    return auth.user.pipe(
      take(1),
      switchMap((user) => {
        if (user) {
          return store.select(currentUserFeature.selectCurrentUser).pipe(
            filter(isNonNullish),
            take(1),
            switchMap((user) => {
              if (isNullish(user?.id)) {
                return of(
                  router.createUrlTree(['auth', 'login'], {
                    queryParams: {
                      redirect: state.url,
                    },
                  }),
                );
              }

              if (user.is_approved) {
                return of(true);
              }

              return of(router.createUrlTree([OrquestaRoute.Waitlist]));
            }),
          );
        }

        return of(router.createUrlTree(['auth', 'login']));
      }),
    );
  };
}

export function nonWaitlistGuard(): CanActivateFn {
  return () => {
    const router = inject(Router);
    const store = inject(Store);
    const auth = inject(AngularFireAuth);

    return auth.user.pipe(
      take(1),
      switchMap((user) => {
        if (isNonNullish(user)) {
          return store.select(currentUserFeature.selectCurrentUser).pipe(
            filter(isNonNullish),
            take(1),
            switchMap((user) => {
              if (user.is_approved) {
                return of(router.createUrlTree(['']));
              }
              return of(true);
            }),
          );
        }

        return of(router.createUrlTree(['auth', 'login']));
      }),
    );
  };
}
