import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Auth, user } from '@angular/fire/auth';
import { excludeFromInterceptor, isNonNullish } from '@orquesta/utils/common';
import { first, from, of, switchMap } from 'rxjs';

export function TokenInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
) {
  const auth = inject(Auth);

  if (excludeFromInterceptor(request.url)) {
    return next(request);
  }

  return user(auth).pipe(
    first(isNonNullish),
    switchMap((user) => from(user?.getIdToken() ?? of(null))),
    switchMap((token) => {
      return next(
        request.clone({ setHeaders: { Authorization: `Bearer ${token}` } }),
      );
    }),
  );
}
