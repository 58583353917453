import {
  HttpContextToken,
  HttpHandlerFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrquestaHeaders, SafeAny } from '@orquesta/models/common';
import { workspacesFeature } from '@orquesta/state/workspaces';
import { excludeFromInterceptor } from '@orquesta/utils/common';
import { switchMap, take } from 'rxjs';

export const SKIP_INTERCEPTOR = new HttpContextToken<boolean>(() => false);

export function WorkspaceInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
) {
  const store = inject(Store);

  const requestBody: SafeAny = request.body;

  if (excludeFromInterceptor(request.url)) {
    return next(request);
  }

  return store.select(workspacesFeature.selectActiveWorkspace).pipe(
    take(1),
    switchMap((workspace) => {
      if (workspace) {
        if (['POST', 'PUT', 'PATCH'].includes(request.method)) {
          if (requestBody instanceof FormData) {
            requestBody.set('workspace', workspace.id);
          } else {
            request = request.clone({
              body: { ...requestBody, workspace: workspace.id },
            });
          }
        }

        request = request.clone({
          setHeaders: {
            [OrquestaHeaders.ORQ_WORKSPACE_KEY]: workspace.key,
            [OrquestaHeaders.ORQ_WORKSPACE_ID]: workspace.id,
            [OrquestaHeaders.X_ORQ_WORKSPACE_ID]: workspace.id,
          },
        });
      }
      return next(request);
    }),
  );
}
