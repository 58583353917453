import { inject } from '@angular/core';
import { Auth, verifyPasswordResetCode } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { OrquestaRoute } from '@orquesta/models/common';
import { isNullish } from '@orquesta/utils/common';
import { catchError, from, map, of, take } from 'rxjs';

export const setPasswordGuard = (): CanActivateFn => {
  return (route: ActivatedRouteSnapshot) => {
    const router = inject(Router);
    const auth = inject(Auth);
    const loginRedirect$ = of(router.createUrlTree([OrquestaRoute.Login]));
    const { code } = route.queryParams;

    if (isNullish(code)) {
      return loginRedirect$;
    }

    return from(verifyPasswordResetCode(auth, code)).pipe(
      take(1),
      map(() => true),
      catchError(() => loginRedirect$),
    );
  };
};
