import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrquestaHeaders } from '@orquesta/models/common';
import { currentUserFeature } from '@orquesta/state/current-user';
import { switchMap, take } from 'rxjs';

export function AccountIdInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
) {
  return inject(Store)
    .select(currentUserFeature.selectCurrentUserAccountId)
    .pipe(
      take(1),
      switchMap((accountId) => {
        if (accountId) {
          request = request.clone({
            setHeaders: {
              [OrquestaHeaders.X_ORQ_ACCOUNT_ID]: accountId,
            },
          });
        }

        return next(request);
      }),
    );
}
