import { Component, OnInit, inject, signal } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Store } from '@ngrx/store';
import { appFeature } from '@orquesta/state/app';
import { slideFromBottom } from '@orquesta/ui/core';
import { IconsModule } from '@orquesta/ui/icons';
import { LoaderComponent } from '@orquesta/ui/loader';
import { filter } from 'rxjs';

@Component({
  standalone: true,
  selector: 'ox-root',
  templateUrl: './app.component.html',
  imports: [RouterOutlet, LoaderComponent, IconsModule],
  animations: [slideFromBottom],
})
export class AppComponent implements OnInit {
  private swUpdate = inject(SwUpdate);
  private store = inject(Store);
  private router = inject(Router);

  showVersionUpdate = signal(false);

  appLoading = this.store.selectSignal(appFeature.selectLoading);

  public ngOnInit() {
    this.checkVersionUpdates();
  }

  reloadPage() {
    window.location.reload();
  }

  public checkVersionUpdates() {
    this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      )
      .subscribe(() => {
        this.showVersionUpdate.set(true);
      });
  }
}
