import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BillingService } from '@orquesta/data/billing';
import { OrquestaRule } from '@orquesta/models/common';
import { currentUserFeature } from '@orquesta/state/current-user';
import { OrquestaFacade } from '@orquesta/state/orquesta';
import { filterNil } from '@orquesta/utils/common';
import { combineLatest, of, switchMap, take } from 'rxjs';

export const onboardingGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);
  const orquestaFacade = inject(OrquestaFacade);
  const billingService = inject(BillingService);

  const enableOnboarding$ = orquestaFacade
    .queryRule(OrquestaRule.EnableOnboarding, false)
    .pipe(filterNil());
  const enableIsStaff$ = orquestaFacade
    .queryRule(OrquestaRule.EnableIsStaff, false)
    .pipe(filterNil());
  const user$ = store
    .select(currentUserFeature.selectCurrentUser)
    .pipe(filterNil());
  const enableBilling$ = orquestaFacade
    .queryRule(OrquestaRule.EnableBilling, false)
    .pipe(filterNil());

  return combineLatest([
    enableOnboarding$,
    enableIsStaff$,
    user$,
    enableBilling$,
  ]).pipe(
    take(1),
    switchMap(([enableOnboarding, enableIsStaff, user, enableBilling]) => {
      const workspaceKey = user.preferences?.active_workspace;

      if (
        !enableOnboarding ||
        (enableIsStaff && user.is_staff) ||
        !workspaceKey ||
        !enableBilling
      ) {
        return of(true);
      }

      return billingService.hasSubscription(workspaceKey).pipe(
        switchMap((subscription) => {
          if (!subscription) {
            return of(true);
          }

          return router.navigate([workspaceKey]);
        }),
      );
    }),
  );
};
