import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { isNonNullish } from '@orquesta/utils/common';

@Injectable({ providedIn: 'root' })
export class TitlePageStrategyService extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);

    if (isNonNullish(title)) this.title.setTitle(`orq.ai | ${title}`);
  }
}
