import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  input,
} from '@angular/core';
import { DataTestIdDirective } from '@orquesta/ui/core';

@Component({
  standalone: true,
  selector: 'ox-loader',
  template: `
@if(show()){


    <div class="loader-wrapper">
      <div class="flex gap-x-0.5">
        <svg width="50" height="50" viewBox="-5 -5 60 60" xmlns="http://www.w3.org/2000/svg">
          <path id="animatedLetter" d="M25.128 49.48C21.864 49.48 18.76 48.84 15.816 47.56C12.872 46.28 10.28 44.52 8.04 42.28C5.8 40.04 4.04 37.448 2.76 34.504C1.48 31.56 0.84 28.456 0.84 25.192C0.84 21.864 1.48 18.696 2.76 15.688C4.04 12.68 5.8 10.056 8.04 7.816C10.28 5.576 12.872 3.848 15.816 2.632C18.76 1.352 21.864 0.711998 25.128 0.711998C28.456 0.711998 31.624 1.352 34.632 2.632C37.64 3.848 40.264 5.576 42.504 7.816C44.744 10.056 46.472 12.68 47.688 15.688C48.968 18.696 49.608 21.864 49.608 25.192C49.608 28.456 48.968 31.56 47.688 34.504C46.472 37.448 44.744 40.04 42.504 42.28C40.264 44.52 37.64 46.28 34.632 47.56C31.624 48.84 28.456 49.48 25.128 49.48ZM25.128 9.256C20.84 9.256 17.16 10.792 14.088 13.864C12.616 15.336 11.464 17.064 10.632 19.048C9.8 21.032 9.384 23.08 9.384 25.192C9.384 27.304 9.8 29.32 10.632 31.24C11.464 33.16 12.616 34.856 14.088 36.328C15.56 37.8 17.224 38.952 19.08 39.784C21 40.616 23.016 41.032 25.128 41.032C27.24 41.032 29.288 40.616 31.272 39.784C33.256 38.952 34.952 37.8 36.36 36.328C37.768 34.856 38.888 33.16 39.72 31.24C40.552 29.32 40.968 27.304 40.968 25.192C40.968 23.08 40.552 21.032 39.72 19.048C38.888 17.064 37.768 15.336 36.36 13.864C34.952 12.392 33.256 11.272 31.272 10.504C29.352 9.672 27.304 9.256 25.128 9.256Z" stroke="#24059D" stroke-width="4" fill="none" />
        </svg>
      
        <svg width="32" height="50" viewBox="-5 -5 41 59" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path id="animatedLetter" d="M22.208 10.216C20.672 9.512 19.296 9.16 18.08 9.16C15.52 9.16 13.408 10.056 11.744 11.848C9.952 13.768 9.056 15.976 9.056 18.472V49H0.8V18.472C0.8 15.016 1.664 11.848 3.392 8.968C5.184 6.088 7.616 3.848 10.688 2.248C12.992 1.16 15.456 0.615999 18.08 0.615999C20.192 0.615999 22.208 0.999999 24.128 1.768C26.048 2.536 28.064 3.784 30.176 5.512L22.208 10.216Z" stroke="#24059D" stroke-width="4" fill="none" />
        </svg>
      
       <svg class="-translate-x-1" width="50" height="70" viewBox="-5 -5 60 80" xmlns="http://www.w3.org/2000/svg">
          <path id="animatedLetter" d="M40.776 69.448V42.568C38.856 44.808 36.456 46.6 33.576 47.944C30.76 49.224 27.912 49.864 25.032 49.864C21.768 49.864 18.632 49.224 15.624 47.944C12.68 46.664 10.12 44.904 7.944 42.664C5.704 40.36 3.944 37.736 2.664 34.792C1.448 31.848 0.84 28.712 0.84 25.384C0.84 22.056 1.448 18.92 2.664 15.976C3.944 12.968 5.704 10.312 7.944 8.008C10.12 5.704 12.68 3.944 15.624 2.728C18.632 1.448 21.768 0.807997 25.032 0.807997C28.04 0.807997 30.92 1.544 33.672 3.016C36.424 4.424 38.792 6.344 40.776 8.776V2.152H49.32V69.448H40.776ZM25.032 9.544C22.92 9.544 20.904 9.96 18.984 10.792C17.064 11.56 15.4 12.68 13.992 14.152C12.584 15.624 11.464 17.352 10.632 19.336C9.864 21.256 9.48 23.272 9.48 25.384C9.48 27.496 9.864 29.512 10.632 31.432C11.464 33.352 12.584 35.048 13.992 36.52C15.4 37.992 17.064 39.144 18.984 39.976C20.904 40.808 22.92 41.224 25.032 41.224C27.144 41.224 29.16 40.808 31.08 39.976C33 39.144 34.664 37.992 36.072 36.52C37.48 35.048 38.6 33.352 39.432 31.432C40.264 29.512 40.68 27.496 40.68 25.384C40.68 23.272 40.264 21.256 39.432 19.336C38.6 17.352 37.48 15.624 36.072 14.152C34.664 12.68 33 11.56 31.08 10.792C29.16 9.96 27.144 9.544 25.032 9.544Z" stroke="#24059D" stroke-width="4" fill="none" />
      </svg>
      </div>
    </div>
  }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, DataTestIdDirective],
})
export class LoaderComponent {
  public show = input.required();

  public readonly dataCyParent = 'loader';
}
